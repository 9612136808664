import React, { Suspense } from "react";
import Header from "./components/Header";
import Heading from "./ui/heading";
import Hero from "./components/Hero";
import GridFolders from "./components/GridFolders";
import { Gallery } from "./components/Gallery";
import AboutUs from "./components/AboutUs";
import CarouselCompany from "./components/CarouselCompany";
import CarouselText from "./components/CarouselText";
import Footer from "./components/Footer";
import { useTranslation } from "react-i18next";

const Video = React.lazy(() => import("./components/VideoYandex/Video"));
const DragFolders = React.lazy(() => import("./components/DragFolders"));
const ProjectForm = React.lazy(() => import("./components/ProjectForm"));
const Review = React.lazy(() => import("./components/Reviews"));

function App() {
  const { t } = useTranslation();

  return (
    <>
      <main className="md:px-10 px-4 max-w-[1440px] mx-auto">
        <Header />
        <Hero />
        <Heading title={t("whoWeTitle")} text={t("whoWeText")} />
        <GridFolders />
        {/* <Video /> */}
        <Heading title={t("OurApproachTitle")} text={t("OurApproachText")} />
        <Suspense fallback={<div>Loading...</div>}>
          <DragFolders />
        </Suspense>
        <AboutUs />
        <CarouselCompany />
        <Review />
        <Suspense fallback={<div>Loading...</div>}>
          <Video />
        </Suspense>
        <Heading
          title={t("WhatIsImportantTitle")}
          text={t("WhatIsImportantText")}
        />
        <Gallery />
        <CarouselText />
        <Suspense fallback={<div>Loading...</div>}>
          <ProjectForm />
        </Suspense>
        <Footer />
      </main>
    </>
  );
}

export default App;
