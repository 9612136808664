import { useState } from "react";
import classNames from "classnames";
import "./index.css";
import { useTranslation } from "react-i18next";

const Slider = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const { t } = useTranslation();

  const menuItems = [
    { label: t("WhoAreWe"), index: 0 },
    { label: t("Team"), index: 1 },
    { label: t("WhyAreWe"), index: 2 },
  ];

  const slides = [
    {
      text: "Today Digital — это креативная дизайн-студия, ориентированная на работу в короткие сроки. Мы талантливые профессионалы в области цифрового дизайна и маркетинга, предоставляем широкий спектр услуг для создания уникальных и эффективных решений <br/><br/>Наша специализация охватывает видео-продакшен, 3д, анимации, разработку интерфейсов, баннеров и контента для социальных сетей и другие. Обеспечиваем всестороннюю дизайн-поддержку",
      height: 315,
    },
    {
      text: "Время для нас — это не преграда, а мощный  стимул, чтобы расти каждый день. Не боимся проектов со сжатыми сроками. Новые челленджи помогают расти нам каждый день",
      height: 135,
    },
    {
      text: "С нами удобно как в работе, так и в коммуникации. Почему выбирают нашу студию: <br/><br/><strong>Оперативность</strong>: Мы работаем быстро и эффективно, соблюдая сжатые сроки и не теряя при этом в качестве <br/><br/><strong>Креативность</strong>: Мы предлагаем инновационные и нестандартные решения, которые помогают вашему бизнесу выделиться и привлечь внимание целевой аудитории <br/><br/><strong>Индивидуальный подход</strong>: Мы внимательно слушаем ваши потребности и разрабатываем уникальные решения, которые точно соответствуют вашим целям и задачам<br/><br/> <strong>Открытость</strong>: Мы строим отношения на честности и прозрачности, поддерживая открытый диалог и доверие с клиентами на всех этапах работы",
      height: 520,
    },
  ];

  const showSlide = (index) => {
    setCurrentSlide(index);
  };

  const nextSlide = () => {
    if (currentSlide < slides.length - 1) {
      setCurrentSlide(currentSlide + 1);
    }
  };

  const prevSlide = () => {
    if (currentSlide > 0) {
      setCurrentSlide(currentSlide - 1);
    }
  };

  const isNextDisabled = currentSlide === slides.length - 1;
  const isPrevDisabled = currentSlide === 0;

  return (
    <div className="container-carousel" id="about-us">
      <div className="sidebar">
        <div className="sidebar-top">
          <div className="row-dots-2">
            <span className="dot-2"></span>
            <span className="dot-2"></span>
            <span className="dot-2"></span>
          </div>
          <p className="sidebar-title">О нас</p>
        </div>

        <div className="sidebar-items">
          {menuItems.map((item) => (
            <div
              key={item.index}
              className={classNames("menu-item", {
                active: currentSlide === item.index,
              })}
              onClick={() => showSlide(item.index)}
            >
              {item.label}
            </div>
          ))}
        </div>
      </div>
      <div className="content">
        {slides.map((slide, index) => (
          <div
            key={index}
            className={classNames("slide", { active: currentSlide === index })}
            style={
              currentSlide === index ? { height: slide.height } : { height: 0 }
            }
            dangerouslySetInnerHTML={{ __html: slide.text }}
          ></div>
        ))}
        <div className="navigation">
          <button
            className="nav-btn"
            onClick={prevSlide}
            disabled={isPrevDisabled}
          >
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.0002 2.39999C8.50097 2.39999 2.40017 8.50079 2.40017 16C2.40017 23.4992 8.50097 29.6 16.0002 29.6C23.4994 29.6 29.6002 23.4992 29.6002 16C29.6002 8.50079 23.4994 2.39999 16.0002 2.39999ZM16 32C7.1776 32 0 24.8224 0 16C0 7.1776 7.1776 0 16 0C24.8224 0 32 7.1776 32 16C32 24.8224 24.8224 32 16 32Z"
                fill="#2471FC"
              />
              <path
                d="M13.0494 15.1192L17.6671 10.1535L19.3477 11.8341L15.2906 16.239L19.3475 20.4979L17.8281 22.0173L13.0494 17.0365C12.5199 16.5071 12.5199 15.6471 13.0494 15.1176L13.0494 15.1192Z"
                fill="#2471FC"
              />
            </svg>
          </button>
          <button
            className="nav-btn"
            onClick={nextSlide}
            disabled={isNextDisabled}
          >
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.0002 2.39991C8.50097 2.39991 2.40017 8.5007 2.40017 15.9999C2.40017 23.4991 8.50097 29.5999 16.0002 29.5999C23.4994 29.5999 29.6002 23.4991 29.6002 15.9999C29.6002 8.5007 23.4994 2.39991 16.0002 2.39991ZM16 32C7.1776 32 0 24.8224 0 16C0 7.1776 7.1776 0 16 0C24.8224 0 32 7.1776 32 16C32 24.8224 24.8224 32 16 32Z"
                fill="#2471FC"
              />
              <path
                d="M18.9301 16.8805L14.3124 21.8462L12.6318 20.1657L16.6889 15.7607L12.632 11.5018L14.1513 9.98242L18.9301 14.9632C19.4596 15.4927 19.4596 16.3527 18.9301 16.8822V16.8805Z"
                fill="#2471FC"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Slider;
